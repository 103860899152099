<template>
    <div class="row">
        <label for="first_name" class="col-sm-2 col-form-label">Voornaam</label>
        <div class="col-sm-8">
            <input v-model="localUser.first_name" class="form-control form-control-sm" id="first_name" />
        </div>
    </div>
    <div class="row">
        <label for="last_name" class="col-sm-2 col-form-label">Achternaam</label>
        <div class="col-sm-8">
            <input v-model="localUser.last_name" class="form-control form-control-sm" id="last_name" />
        </div>
    </div>
    <div class="row">
        <label for="email" class="col-sm-2 col-form-label">E-mail</label>
        <div class="col-sm-8">
            <input v-model="localUser.email" class="form-control form-control-sm" id="email" />
        </div>
    </div>
    <div class="row">
        <label for="password" class="col-sm-2 col-form-label">Wachtwoord</label>
        <div class="col-sm-8">
            <input v-model="localUser.password" class="form-control form-control-sm" id="password" type="password"/>
        </div>
    </div>
    <div class="row">
        <label for="name" class="col-sm-2 col-form-label">Adviseur</label>
        <div class="col-sm-8">
            <input class="form-check" type="checkbox" id="adviser" v-model="localUser.is_adviser" :true-value="1" :false-value="0">
        </div>
    </div>
    <div class="row">
        <label for="role" class="col-sm-2 col-form-label">Rol</label>
        <div class="col-sm-8">
            <select v-model="localUser.user_role_id" class="form-select form-select-sm"
                id="role">
                <option value=''>Selecteer...</option>
                <option v-for="role in roles" :key="role.id"
                    :value="role.id">
                    {{role.name}}
                </option>
            </select>
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2 col-form-label">Handtekening</label>
        <div class="col-sm-8">
            <input class="form-control form-control-sm" id="" type="file" @change="handleFileChange"/>
        </div>
    </div>
    <div class="row" >
        <label for="name" class="col-sm-2 col-form-label"></label>
        <div class="col-sm-8" v-if="localUser.signature64">
            <img :src="localUser.signature64" style="width:200px;">
        </div>
        <div class="col-sm-8" v-else>
            <div>Geen handtekening</div>
        </div>
    </div>
</template>
                    


<script>
    import userservice from '@/services/admin/UserService'

    export default {
        name: 'AdminUserDetailComponent',
        props: ['itemData'],
        data() {
            return {
                localUser: {},
                roles: [],
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.localUser = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');
                return valid;
            },
            getData() {
                return this.localUser;
            },
            setData(data) {
                
                this.localUser = data
            },
            reset() {
                this.localUser = {
                    'email': '',
                    'name': '',
                    'user_role_id': '',
                    'is_adviser': false,
                    'signature': null
                },

                this.loading = false
                this.invalid = {}
            },
            handleFileChange(evt) {
                var files = evt.target.files || evt.dataTransfer.files;
                
                if (!files.length) {
                    return;
                }
                
                var file = files[0];    
                var reader  = new FileReader();

                reader.onload = (e) => {
                    const imgBase64Path = e.target.result;
                    this.localUser.signature = imgBase64Path
                }

                if (file) {
                    reader.readAsDataURL(file)
                }
            },
            getRoles() {
                userservice.getRoles().then(response => {
                    this.roles = response.data
                }).catch(error => {
                    this.$toast.error('Failed to get Role data')
                })
            },
        },
        mounted() {
            this.reset()
            this.getRoles()
        }
    }
</script>