<template>
    <Overview 
        moduleName="Gebruiker"
        moduleNamePlural="Gebruikers"
        :sort="'email'"
        :itemService=itemService
        :itemFormRef=itemFormRef
    >
        <template #columns>
            <Column field="name" header="Naam">
              <template #body="slotProps">
                {{ getName(slotProps.data) }}
              </template>
            </Column>
            <Column field="email" header="E-mail" :sortable="true"></Column>
            <Column field="user_role.name" header="Rol" :sortable="true"></Column>
            <Column field="is_adviser" header="Adviseur?" :sortable="true">
              <template #body="slotProps">
                  <i v-if='slotProps.data.is_adviser' class='fas fa-check text-success' />
              </template>
            </Column>
        </template>
        
        <template #form>
            <AdminUserDetailComponent ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import AdminUserDetailComponent from '@/components/admin/modals/AdminUserDetailComponent';
import userservice from '@/services/admin/UserService'

export default {
  components: {
      Overview,
      Column,
      AdminUserDetailComponent
  },
  data() {
      return {
          item: {},
          itemService: userservice,
          itemFormRef: null
      }
    },
    methods: {
      
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>