<template>
    <div>
        <h1>{{moduleNamePlural}}</h1>

        <DataTable ref="dt" 
            :value="items.data" dataKey="id" 
            :totalRecords="items.total"
            :sortField="sort" :sortOrder="sortOrder"
            :paginator="true" :rows="15" :alwaysShowPaginator="false" :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" responsiveLayout="scroll"
            :lazy="true" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" 
        >
            <template #header>
                <div class='row'>
                    <div class='col-sm-9'>
                        <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#formModal" @click="addItem()" id='btnNew'><i class="fal fa-plus"></i>&ensp;Toevoegen</button>
                    </div>
                    <div class='col-sm-3 text-end'>
                        <input class="form-control" type="search" placeholder="Zoeken..." id="example-search-input" @change="onFilter()" @input="onInput()" v-model="filters['global'].value" autocomplete="off">
                    </div>
                </div>
            </template>

            <slot name="columns"></slot>
            
            <Column :exportable="false" style="width: 10rem">
                <template #body="slotProps">
                    
                    <div class="text-end">
                        <a v-if="moduleNamePlural == 'Quotations'" class="fa-stack" @click="downloadPDF(slotProps.data)">
                            <i class="fas fa-circle fa-stack-2x text-success"></i>
                            <i class="fal fa-download fa-stack-1x fa-inverse"></i>
                        </a>

                        <a class="fa-stack" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data)">
                            <i class="fas fa-circle fa-stack-2x text-warning"></i>
                            <i class="fal fa-pencil-alt fa-stack-1x fa-inverse"></i>
                        </a>

                        
                        <a v-if="moduleName != 'Locales'" class="fa-stack" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="delItem(slotProps.data)">
                            <i class="fas fa-circle fa-stack-2x text-danger"></i>
                            <i class="fal fa-trash-alt fa-stack-1x fa-inverse"></i>
                        </a> 
                    </div>
                </template>
            </Column>
            
        </DataTable>

        <FormPopup v-on:save="save" v-on:del="del" :itemId="item ? item.id : null" :itemName="item ? item[sort] : null" :moduleName="moduleName" :loading="itemLoading" :modalSize="modalSize" :modalHeight="modalHeight" :modalWidth="modalWidth">
            <slot name='form'></slot>
            <template #modal-footer><slot name="modal-footer"></slot></template>
        </FormPopup>
    </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FormPopup from '@/components/FormPopup.vue';

export default {
    name: 'Overview',
    props: {
        'moduleNamePlural': {},
        'moduleName': {},
        'sort': {},
        'sortOrder': { type: Number, default: 1},
        'itemService': {},
        'itemFormRef': {},
        'modalSize': {},
        'modalHeight': {},
        'modalWidth': {},
        'selectFields': { type: Array }
    },
    components: {
        DataTable,
        Column,
        FormPopup,
        // PortForm
    },
    emits: 'refreshList',
    data() {
        return {
            items: {
                'data': [],
                'total': 0
            },
            loading: true,
            lazyParams: {},
            page: 1,
            filters: {},
            
            item: {},
            itemLoading: false
        }
    },
    methods: {
        onPage(event) {
            this.page = event.page + 1
            this.getIndex();
        },
        onSort(event) {
            this.page = 1;
            this.lazyParams = event;
            this.lazyParams.route = this.$route.name
            this.getIndex();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.getIndex();
        },
        onInput() {
            if(this.filters['global'].value == '') {
                this.onFilter();
            }
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        getIndex() {
          this.loading = true
          this.item = {}
          this.itemService.index(this.page, {
            lazyEvent: JSON.stringify(this.lazyParams)
          }).then(response => {
            this.items = response.data;
            this.loading = false
          }).catch(error => {
            console.log('error', error)
          })
        },
        addItem() {
            this.itemLoading = false;
            this.itemFormRef.reset();
            this.item = this.itemFormRef.getData();
            // console.log("Item is now", this.item);
        },
        editItem(item) {
            this.itemLoading = true;
            this.itemFormRef.reset();

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
        delItem(item) {
            this.item = {...item};
        },
        downloadPDF(item) {
            this.item = {...item};
            this.itemService.downloadPDF(this.item.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.item.number + ".pdf");
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                this.toastError(error)
            })

        },
        save() {
            let validate = this.itemFormRef.validate();
            if(!validate) return false;
            let item = this.itemFormRef.getData();
            
            if(this.item && this.item.id) {
                this.itemService.update(item).then(() => {
                    this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#formModal .btn-close").click();
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            } else {
                this.itemService.store(item).then(() => {
                    this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#formModal .btn-close").click();
                }).catch(error => {
                        this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            }
            
        },
        del() {
            this.itemService.delete(this.item.id).then(() => {
                this.getIndex();
                document.querySelector("#deleteModal .btn-close").click();
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
            
        }
    },
    created() {
        // this.productService = new ProductService();
        this.initFilters();
    },
    mounted() {
        this.lazyParams = {
          first: 0,
          rows: this.$refs.dt.rows,
          sortField: this.sort,
          sortOrder: this.sortOrder,
          filters: this.filters,
          route: this.$route.name,
          selectFields: this.selectFields
        }
        this.getIndex();

        
    }
}
</script>

<style scoped>

    .ms-n5 {
        margin-left: -40px;
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.fa-stack {
        cursor: pointer;
    }

</style>

<style>
.pi-sort-alt:before {
    content: "";
}
</style>